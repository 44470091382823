const data = {

    /* Preloader */
    "Supported by": "При поддержке",

    /* App */
    "Poor internet connection": "Плохое соединение с интернетом",
    "Transaction error": "Ошибка транзакции",
    "You must select 2 or more cards": "Вам необходимо выбрать 2 или более карт",
    "The game ended due to the administrator's initiative": "Игра завершена по инициативе администратора",

    /* Error */
    "Reload": "Перезагрузить",
    "Error!": "Ошибка!",
    "Your session has expired due to inactivity!": "Ваш сеанс завершился из-за неактивности!",
    "To start the game again, please refresh the page.": "Чтобы начать игру заново, перезагрузите страницу",
    "You are logged out of the game!": "Вы вышли из игры!",
    "To continue, please refresh the page.": "Чтобы продолжить, перезагрузите страницу",
    "You have entered the game from a new tab.": "Вы вошли в игру с новой вкладки",
    "Something went wrong! Try again later or refresh the page.": "Что-то пошло не так! Попробуйте позднее или перезагрузите страницу.",


    /* GAME */
    "Payout": "Выплата",
    "NO GAME": "НЕТ ИГРЫ",
    "ACE KING": "ТУЗ КОРОЛЬ",
    "ROYAL FLUSH": "РОЯЛ ФЛЭШ",
    "STRAIGHT FLUSH": "СТРИТ ФЛЭШ",
    "FOUR OF A KIND": "КАРЕ",
    "FULL HOUSE": "ФУЛЛ ХАУС",
    "FLUSH": "ФЛЭШ",
    "STRAIGHT": "СТРИТ",
    "THREE OF A KIND": "ТРОЙКА",
    "TWO PAIRS": "ДВЕ ПАРЫ",
    "PAIR": "ПАРА",

    /* Balance */
    'BALANCE:': 'БАЛАНС:',
    'LAST WIN:': 'ПОСЛЕДНИЙ ВЫИГРЫШ',
    'TOTAL:': 'ОБЩАЯ СТАВКА:',
    "Cashier": "Касса",

    /* Chips */
    'Clear': 'Очистить',
    'Cancel': 'Отменить',
    'Double': 'Удвоить',
    'Repeat': 'Повторить',
    "You don't have enough funds": "У вас недостаточно средств",
    "Maximum bid": "Максимальная ставка",
    "Maximum bid for bonus": "Максимальная ставка для бонуса",
    "Insurance won": "Страховка выиграла",
    "Insurance lost": "Страховка проиграла",

    /* Dealer Cards */
    "DEALER'S HAND": "РУКА ДИЛЕРА",

    /* History */
    "History": "История",
    "Game": "Игра",
    "Balance before the game:": "Баланс до игры:",
    "Balance after the game:": "Баланс после игры:",
    "Load more": "Загрузить ещё",

    'Today': 'Сегодня',
    'Yesterday': 'Вчера',
    'Dealer': 'Дилер',
    'Player': 'Игрок',
    'Type': 'Вид ставки',
    'Amount': 'Ставка',
    'Paid': 'Выплата',
    'Total': 'Итого',

    "ANTE": "ANTE",
    "BONUS": "БОНУС",
    "BET": "BET",
    "INSURANCE": "СТРАХОВКА",
    "PURCHASE OF A GAME FOR A DEALER": "ПОКУПКА ИГРЫ У ДИЛЕРА",
    "6-CARD": "6-КАРТА",
    "EXCHANGE": "ОБМЕН",
    "FOLD": "ПАС",
    "WIN": "ВЫИГРЫШ",

    "Refunds": "Возвраты",
    "Replenishment of the amount": "Пополнение суммы",
    "Return of funds": "Возврат средств",

    /* Insurance */
    "Select insurance chip": "Выберите фишку",
    "Close": "Закрыть",

    /* Mobile Payout */
    "Bonus": "Бонус",
    "Maximum payout": "Максимальная выплата",

    /* Player Cards */
    "YOUR HAND": "ВАША РУКА",

    /* PURCHASE */
    "BUY A GAME FROM A DEALER?": "КУПИТЬ ИГРУ У ДИЛЕРА?",
    "YES": "ДА",
    "NO": "НЕТ",

    /* Result */
    "DEALER WINS": "ДИЛЕР ВЫИГРАЛ",
    "DRAW": "НИЧЬЯ",
    "YOU WIN": "ВЫ ВЫИГРАЛИ",

    /* Rules */
    'Rules': 'Правила',
    'How to play': 'Как играть',

    /* Menu */
    'Menu': 'Меню',

    /* Buttons */
    "+1 CARD": "+1 КАРТА",
    "START": "НАЧАТЬ",
}

export default data