import React, { Component } from "react"

/* Widgets */
import { Background, PlayerCards, DealerCards, Rates, Chips, Balance, Result, InsuranceChips, Purchase, Preloader, Transactions } from './widgets'

/* Components */
import { GameInfo, Fold, Bet, Sixth, Exchange, Start, Payout, Insurance, Picture, Error, InactionTiming, Internet, Notification } from './components'

/* Socket IO */
import { io } from "socket.io-client"

/* JWT */
import { decodeToken } from 'react-jwt'

/* REST API */
import { history } from './api/History'

/* Game quit alicorn */
import { setIframeMessageSenderSettings, useSendInitIframeMessage } from '@alicorn/iframe-message-sender'

/* Constants */
import { env, utils, sound } from "./constants"
import { ERROR, CHOICE, DEALING, GAME, LOADING } from "./constants/status"

/* Rules */
import { rules } from "./constants/rules"

/* Redux */
import { connect } from 'react-redux'

/* Fields */
const pathname = window.location.search
const token = pathname.length > 1 ? pathname.substring(1) : ""

/* FRAME SETTINGS */
setIframeMessageSenderSettings({ parentName: 'makao-front', sourceName: 'poker', isChild: true })

const imgUrl = 'https://repb.rekopgames.com/images/rules/game.png'


/* Entry Point */
class App extends Component {

    constructor() {
        super()

        const data = decodeToken(token)

        this.state = {
            status: LOADING,

            ante: 0,
            bonus: 0,
            selected: 2,
            last: { ante: 0, bonus: 0 },
            bet: 0,
            list: [],
            balance: 0,
            total: 0,
            lastWin: 0,

            solution: "",
            used: false,

            playerCards: [],
            playerGame: null,
            sixthGame: [],
            exchanged: [],
            isExchange: false,

            dealerCards: [],
            dealerGame: null,

            result: null,
            bonusResult: null,
            chip: false,

            insurance: 0,
            insuranceStatus: 0,
            insuranceModal: false,

            isPurchase: false,
            purchase: null,
            removedCard: null,

            gameInfo: null,
            transactions: [],
            operations: [],
            histories: [],
            historyLoading: true,
            historyPage: 1,
            historyHasMore: true,

            showInternetConnectionError: 0,

            /* Game Session */
            closed: false,
            newDevice: false,
            isDisconnected: false,

            currency: data.currency,
            chips: utils.getChips(data.chips),
            game: {
                max: data.max,
                maxBonus: data.maxBonus,
                min: data.min,
                maxPay: data.maxPay
            },

            volume: false,
            locale: data.locale ? data.locale : "ru"
        }

        /* Start connection */
        this.socket = io(env.endpoint, { auth: { token: `${token}`, reconnection: false, isPlayer: true } })

    }

    componentDidMount = () => {

        const { locale } = this.state
        const { setLanguage } = this.props
        setLanguage(locale)

        this.game()
        this.loadHistory()
    }

    /* Load History */
    loadHistory = (page = 1) => {

        const { histories } = this.state

        if (page > 1) {
            this.setState({ historyPage: page })
        }

        history(token, page).then(response => {
            if (response.status === 200) {
                let list = response.data

                if (page > 1) {
                    list = [...histories, ...response.data]
                }

                if (response.data.length < 5) {
                    this.setState({ historyHasMore: false })
                }

                this.setState({ histories: list, historyLoading: false })
            }
        }).catch(() => {
            this.setState({ histories: [], historyLoading: false })
        })
    }


    game = () => {
        /* On error */
        this.socket.on("connect_error", () => {

            const timer = setTimeout(() => {
                this.setState({ status: ERROR })
                clearTimeout(timer)
            }, 1000)

        })

        /*  */
        this.socket.on("disconnect", () => {
            this.setState({ isDisconnected: true })
        })

        /* On connect */
        this.socket.on("connect", () => {
            const timer = setTimeout(() => {
                if (this.state.status !== CHOICE) {
                    this.setState({ status: CHOICE })
                    this.clear()
                }
                clearTimeout(timer)
            }, 2000)
        })

        /* On New Device */
        this.socket.on("newDeviceConnection", () => {
            this.setState({ newDevice: true })
            this.socket.disconnect()
        })


        /* Reconnection */
        this.socket.on("reconnection", data => {

            const time = data.transactionError ? 3500 : 2000

            if (data.transactionError) {
                utils.toggleError(utils.translate("Transaction error"))
            }

            setTimeout(() => {
                this.setState({
                    status: data.status,
                    gameInfo: data.gameInfo,
                    ante: data.ante,
                    bonus: data.bonus,
                    total: data.total,
                    playerCards: data.playerCards,
                    dealerCards: data.dealerCards,
                    exchanged: data.exchangeCards,
                    bet: data.bet,
                    pass: data.pass,
                    isExchange: data.isExchange,
                    sixth: data.sixth,
                    used: data.used,
                    insurance: data.insurance,
                    insuranceStatus: data.insuranceStatus,
                    isPurchase: data.isPurchase,
                    purchase: data.purchase,
                    playerGame: data.playerGame,
                    sixthGame: data.sixthGame,
                    dealerGame: data.dealerGame,
                    bonusResult: data.bonusResult,
                    result: data.result,

                    transactions: data.transactions
                })
            }, time)
        })

        /* On start */
        this.socket.on("status", data => {
            const { status } = this.state
            if (data !== status) {
                this.setState({ status: data })
            }

            if (data === CHOICE) {
                this.clear()
                this.loadHistory()
            }
        })


        /* Balance */
        this.socket.on("balance", data => {
            this.setState({ balance: data })
        })

        /* Get a dealer card */
        this.socket.on("dealer", data => {

            const { dealerCards, volume } = this.state
            const i = dealerCards.findIndex(e => e.uuid === data.uuid)
            let cards = dealerCards

            if (i === -1) {
                cards.push(data)
                this.setState({ dealerCards: cards })

                if (volume) {
                    sound.play('card', 0.2)
                }
            } else if (dealerCards[i].id && i !== 4) {
                let card = dealerCards[i]
                cards.splice(i, 1)
                cards.push(card)
                this.setState({ dealerCards: cards })
            }

        })

        this.socket.on("dealerData", data => {

            const { dealerCards, dealerGame, result } = data
            const { volume } = this.state

            this.setState({ dealerCards, dealerGame, result, lastWin: result && result.result === "win" && result.sum ? result.sum : 0 })

            if (result && (result.result === "win" || result.result === "draw")) {
                if (volume) {
                    sound.play('win', 0.2)
                }
            }
        })

        /* Get a Player card */
        this.socket.on("player", data => {
            const { playerCards, volume } = this.state
            const i = playerCards.findIndex(e => e.uuid === data.uuid)
            if (i === -1) {
                let cards = playerCards
                cards.push(data)
                this.setState({ playerCards: cards })

                if (volume) {
                    sound.play('card', 0.2)
                }
            }
        })

        this.socket.on("playerGame", data => {
            this.setState({ playerGame: data, status: GAME })
        })

        this.socket.on("sixthGame", data => {
            this.setState({ sixthGame: data, status: GAME })
        })

        /* Get Insurance Status */
        this.socket.on("insuranceStatus", data => {

            const { volume } = this.state

            if (data === "win") {
                if (volume) {
                    sound.play('winsmall', 0.2)
                }
            }

            this.setState({ insuranceStatus: data })
        })

        /* Purchase Card */
        this.socket.on("purchase", (data) => {
            const { removedCard, dealerCards, dealerGame } = data
            this.setState({ isPurchase: true, removedCard: removedCard, dealerCards, dealerGame })
        })

        /* Purchase Card */
        this.socket.on("purchaseCard", data => {
            const { dealerCards } = this.state
            let cards = [...dealerCards, data]
            this.setState({ dealerCards: cards, purchase: true })
        })

        /* Purchase Removed Card */
        this.socket.on("purchaseRemovedCard", data => {
            this.setState({ purchaseRemovedCard: data })
        })

        /* Result */
        this.socket.on("bonusResult", bonusResult => {
            const { volume } = this.state

            this.setState({ bonusResult })
            if (bonusResult.result === "win") {
                if (volume) {
                    sound.play('winsmall', 0.2)
                }
            }
        })

        /* Result */
        this.socket.on("result", result => {

            const { volume } = this.state

            this.setState({ result })

            if (result && result.result === "win") {
                this.setState({ lastWin: result.sum })
            }

            if (result && (result.result === "win" || result.result === "draw")) {
                if (volume) {
                    sound.play('win', 0.2)
                }
            }
        })

        /* Get Game Info */
        this.socket.on("gameInfo", data => {
            this.setState({ gameInfo: data })
        })

        /* Get transactions list */
        this.socket.on("transaction", data => {
            if (data.id) {
                const { operations } = this.state

                operations.push(data)
                this.setState({ operations })

                // setTimeout(() => {
                //     let operationsList = this.state.operations
                //     operationsList = operationsList.filter(item => item.id !== data.id)
                //     this.setState({ operations: operationsList })
                // }, 2000)

            }
        })

        this.socket.on("editTransaction", data => {
            const { operations } = this.state
            let list = operations
            const index = list.findIndex(e => e.number === data.number)
            if (index > -1) {
                list[index].status = data.status
                this.setState({ operations: list })
            }
        })


        /* Force End Game */
        this.socket.on("forceEnd", () => {
            this.setState({ status: CHOICE })
            this.loadHistory()
            this.clear()
            utils.toggleError(utils.translate("The game ended due to the administrator's initiative"), "warning")
        })

        /* Notification From Admin */
        this.socket.on("adminNotification", data => {
            if (data && data.title && data.total && data.currency) {
                utils.toggleError(`${utils.translate(data.title)} ${utils.getWithCurrency(data.total, data.currency)}`, "success")
            }
        })

    }

    /* Disconnect user when inaction timer equal to 0 */
    setInactionState = () => {
        this.setState({ closed: true })
        this.socket.disconnect()
        this.clear()
    }

    /* Reser top timing */
    resetTimer = () => {
        if (this._inaction) {
            this._inaction.reset()
        }
    }

    handleClose = (id) => {

        const { showInternetConnectionError } = this.state

        if (id === "internet" && showInternetConnectionError <= 2) {
            this.setState({ showInternetConnectionError: showInternetConnectionError + 1 })
            if (showInternetConnectionError + 1 === 2) {
                setTimeout(() => {
                    this.setState({ showInternetConnectionError: 1 })
                }, 300000)
            }
        }

    }

    /* Check internet speed */
    checkInternetSpeed = (speed) => {

        const { showInternetConnectionError } = this.state

        if (showInternetConnectionError < 2) {
            if (parseInt(speed) < 3) {
                utils.toggleError(utils.translate("Poor internet connection"), "warning", "internet", this.handleClose)
            }
            else {
                utils.toastDismiss('internet')
            }
        }

    }

    clear = () => {
        this.setState({
            ante: 0,
            bonus: 0,
            bet: 0,
            total: 0,
            list: [],
            gameInfo: null,

            solution: "",
            used: false,

            playerCards: [],
            playerGame: null,
            sixthGame: [],
            exchanged: [],
            isExchange: false,

            dealerCards: [],
            dealerGame: null,

            result: null,
            bonusResult: null,

            insurance: 0,
            insuranceStatus: 0,
            insuranceModal: false,

            isPurchase: false,
            purchase: null,
            removedCard: null,

            operations: []
        })
    }

    setStake = (data) => {
        const { ante, bonus } = data
        this.setState({ ante, bonus, total: ante + bonus, lastWin: 0 })

        this.resetTimer()
    }

    start = () => {
        const { ante, bonus } = this.state
        const stake = { ante, bonus }
        if (ante > 0) {
            this.setState({ status: "DEALING", last: stake })
            this.socket.emit('start', stake)
        }

        this.resetTimer()
    }


    /* FOLD ACTION */
    fold = () => {

        const { dealerCards, solution } = this.state

        if (solution !== "BET" && solution !== "FOLD") {

            if (dealerCards.length === 5) {
                this.socket.emit("pass", { status: "ok" })
            }

            this.setState({ solution: "FOLD", status: DEALING, insuranceModal: false })

        }

        this.resetTimer()
    }




    /* BET ACTION */
    bet = () => {

        const { dealerCards, ante, total, isExchange, solution, volume } = this.state

        if (dealerCards.length === 5 && solution !== "BET" && solution !== "FOLD") {

            this.socket.emit("bet", { ok: "ok" })

            if (!isExchange) {
                this.setState({ exchanged: [] })
            }

            this.setState({ solution: "BET", bet: ante * 2, total: total + ante * 2, used: true, status: DEALING, insuranceModal: false })

            if (volume) {
                sound.play('sound', 0.2)
            }

        }

        this.resetTimer()

    }


    /* SIXTH ACTION */
    sixth = () => {

        const { playerCards, playerGame, used, ante, total, volume } = this.state

        if (playerCards.length === 5 && !used) {

            this.socket.emit("sixth", { ok: "ok" })
            playerGame.data = []

            this.setState({ used: true, chip: true, total: total + ante, status: DEALING, insuranceModal: false, playerGame })

            const animatedTimer = setTimeout(() => {
                this.setState({ chip: false })
                clearTimeout(animatedTimer)
            }, 2000)

            if (volume) {
                sound.play('sound', 0.2)
            }

        }

        this.resetTimer()

    }


    /* EXCHANGE ACTION */
    exchange = () => {

        const { playerCards, used, exchanged, ante, total, volume } = this.state

        if (playerCards.length === 5 && !used) {

            if (exchanged.length === 1) {
                utils.toggleError(utils.translate("You must select 2 or more cards"), "error", "exchange")
                return
            }

            this.socket.emit("exchange", exchanged)

            this.setState({ playerGame: null, isExchange: true, chip: true, used: true, total: total + ante, status: DEALING, insuranceModal: false })

            const animatedTimer = setTimeout(() => {
                this.setState({ chip: false })
                clearTimeout(animatedTimer)
            }, 2000)

            if (volume) {
                sound.play('sound', 0.2)
            }

            this.resetTimer()

        }

    }


    /* Insurance */
    insurance = value => {
        const { total } = this.state
        this.setState({ insurance: parseInt(value), insuranceModal: false, total: total + parseInt(value) })
        this.socket.emit('insurance', parseInt(value))

        this.resetTimer()
    }

    /* Purchase */
    setPurchase = value => {

        const { balance, ante, total, volume } = this.state

        if (parseInt(balance) < parseInt(ante)) {
            utils.toggleError(utils.translate("You don't have enough funds"), "error", "nomoney")
            return
        }

        this.setState({ isPurchase: false, purchase: value, total: total + ante })

        this.socket.emit("purchase", value)

        if (volume) {
            sound.play('sound', 0.2)
        }

        this.resetTimer()

    }


    /* Draw Button */
    _buttons = () => {

        const { status, ante, playerCards, dealerCards, playerGame, sixthGame, used, solution, exchanged, isExchange, insurance } = this.state

        const game = sixthGame && sixthGame.length > 0 ? sixthGame[0] : playerGame
        const isInsurance = game && game.level >= 4

        const isFourButton = !used && !isExchange && isInsurance && !insurance ? "four-button" : ""

        if (status === "GAME" && playerCards.length >= 5 && dealerCards.length === 5 && solution === "") {
            return (
                <div className={`buttons ${isFourButton}`}>
                    {!insurance ? <Fold onClick={() => this.fold()} /> : null}
                    <Bet onClick={() => this.bet()} />
                    {!used && exchanged.length === 0 && !isExchange && <Sixth onClick={() => this.sixth()} />}
                    {!used && exchanged.length > 0 && !isExchange && <Exchange onClick={() => this.exchange()} />}
                    {isInsurance && insurance === 0 && <Insurance onClick={() => this.setState({ insuranceModal: true })} isFourButton={isFourButton} />}
                </div>
            )
        }

        if (status === "CHOICE") {
            return (
                <div className="buttons">
                    <Start onClick={() => this.start()} ante={ante} />
                </div>
            )
        }
    }

    /* Draw Content */
    _content = () => {

        /* Fields */
        const { status, ante, bonus, selected, last, list, playerCards, playerGame, sixthGame, dealerCards, dealerGame, solution, balance, used, exchanged, isExchange, removedCard, game, chips, currency, volume } = this.state

        return (
            <div className="content">

                <div className={`game ${status !== "CHOICE" ? "visible" : "hidden"}`}>
                    <DealerCards cards={dealerCards} game={dealerGame} playerCards={playerCards} solution={solution} removedCard={removedCard} />
                    <PlayerCards cards={playerCards} game={playerGame} sixthGame={sixthGame} solution={solution} used={used} exchanged={exchanged} setExchanged={exchanged => this.setState({ exchanged })} isExchange={isExchange} volume={volume} />
                </div>

                <div className={`choice ${status === "CHOICE" ? "visible" : "hidden"}`}>
                    <Chips
                        chips={chips}
                        ante={ante}
                        bonus={bonus}
                        setStake={data => this.setStake(data)}
                        last={last}
                        selected={selected}
                        setSelected={selected => this.setState({ selected })}
                        list={list}
                        setList={list => this.setState({ list })}
                        balance={balance}
                        game={game}
                        currency={currency}
                        volume={volume}
                    />
                </div>

            </div>
        )
    }

    /* Get Rules */
    getRules = () => {

        const { language } = this.props

        if (rules[language]) {
            return rules[language]
        }

        return rules["ru"]
    }

    render = () => {

        const { ante, bonus, selected, status, list, balance, solution, result, bonusResult, bet, total, lastWin, chip, playerGame, sixthGame, insuranceModal, insurance, insuranceStatus, isPurchase, purchase, currency, game, histories, historyHasMore, historyPage, operations, closed, newDevice, isDisconnected, chips, gameInfo, volume } = this.state
        const { language, setLanguage } = this.props

        if (status === LOADING) {
            return <Preloader />
        }

        if (status === ERROR || newDevice || closed || isDisconnected) {
            return <Error newDevice={newDevice} closed={closed} isDisconnected={isDisconnected} />
        }

        const payout = <Payout game={game} currency={currency} />

        const historyData = {
            histories,
            historyHasMore,
        }

        return (
            <Background language={language} setLanguage={language => setLanguage(language)} historyData={historyData} payout={payout} gameInfo={gameInfo} rules={this.getRules()} loadMore={() => this.loadHistory(historyPage + 1)} volume={volume} setVolume={volume => this.setState({ volume })}>
                <div className="app">

                    <InactionTiming time={240} ref={ref => this._inaction = ref} setInactionState={() => this.setInactionState()} />

                    <GameInfo game={game} currency={currency} payout={payout} />
                    <Balance balance={balance} total={total} lastWin={lastWin} currency={currency} />
                    <Transactions info={gameInfo} operations={operations} currency={currency} />

                    {this._content()}

                    <Rates
                        chips={chips}
                        status={status}
                        ante={ante}
                        bonus={bonus}
                        result={result}
                        bonusResult={bonusResult}
                        bet={bet}
                        setStake={data => this.setStake(data)}
                        selected={selected}
                        list={list}
                        setList={list => this.setState({ list })}
                        solution={solution}
                        balance={balance}
                        chip={chip}
                        bottomBet={() => this.bet()}
                        insurance={insurance}
                        insuranceStatus={insuranceStatus}
                        game={game}
                        currency={currency}
                        volume={volume}
                    />

                    {this._buttons()}

                    <Result result={result} currency={currency} />

                    <div className="back-outer">
                        <div className="background" />
                        <div className="glass" />
                        <div className="shadow" />
                    </div>

                    <div className={`makao ${(status === GAME || status === DEALING) ? 'visible' : 'hidden'}`}>
                        <Picture src={`${env.mediapoint}/images/makao.png`} alt="Makao" />
                    </div>

                    <InsuranceChips
                        chips={chips}
                        insuranceModal={insuranceModal}
                        close={() => this.setState({ insuranceModal: false })}
                        setInsurance={value => this.insurance(value)}
                        ante={ante}
                        balance={balance}
                        playerGame={playerGame}
                        sixthGame={sixthGame}
                        maxPay={game.maxPay}
                    />

                    <Purchase isPurchase={isPurchase} purchase={purchase} setPurchase={(value) => this.setPurchase(value)} />

                    <HookWrapper />

                    <Notification />

                    <Internet url={imgUrl} setInternetSpeed={data => this.checkInternetSpeed(data)} />

                </div>
            </Background>
        )
    }

}
const mapStateToProps = state => {
    return {
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: data => dispatch({ type: 'SET_LANGUAGE', payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

function HookWrapper() {
    useSendInitIframeMessage()
    return null
}