import React, { useEffect, useState, forwardRef, useRef } from "react"

/* FullScreen */
import { FullScreen, useFullScreenHandle } from "react-full-screen"

/* Picture */
import { Picture, GameModal, ModalAnimated, Cash } from "../components"

/* Widgets */
import History from "./History"
import MobilePayout from "./MobilePayout"
import Rules from "./Rules"

/* Constants */
import { env, sound, utils } from "../constants"

/* Languages */
import { languages } from "../translates"



const Background = forwardRef(({ children, payout, rules, historyData, loadMore, gameInfo, volume, setVolume, language, setLanguage }, ref) => {

    /* Detect Safari */
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    /* Hooks */
    const [historyVisible, setHistoryVisible] = useState(false)
    const [rulesVisivle, setRulesVisivle] = useState(false)

    const [menuVisible, setMenuVisible] = useState(false)
    const [selected, setSelected] = useState("")

    const [isPlayed, setIsPlayed] = useState(false)
    const [muted, setMuted] = useState(true)

    /* Refs */
    const screen = useFullScreenHandle()
    const modalRef = useRef(null)

    /* On Mount */
    useEffect(() => {

        if (!isPlayed) {
            document.addEventListener('click', click, true)
        }

        const handleClickOutside = event => {

            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setHistoryVisible(false)
                setRulesVisivle(false)
            }
        }

        document.addEventListener('click', handleClickOutside, true)

        return () => {
            sound.backgroundSound.stop()
            document.removeEventListener('click', click, true)
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [isPlayed])


    const click = () => {

        sound.backgroundSound.play()

        sound.soundEffects.forEach(soundEffect => {
            soundEffect.audio.play()
            soundEffect.audio.pause()
        })

        setIsPlayed(true)
        setMuted(false)
        setVolume(true)
    }

    const mute = () => {

        if (!muted) {
            sound.backgroundSound.pause()
        }
        else {
            sound.backgroundSound.play()
        }

        setMuted(!muted)
    }

    /* Full Screen handler */
    const handle = () => {

        try {
            if (screen.active === true) {
                screen.exit()
            }
            else {
                screen.enter()
            }
        }
        catch {
            console.log("Fullscreen error")
        }

    }

    const toggleButton = (type) => {
        if (type === 'history') {
            setHistoryVisible(!historyVisible)
            setRulesVisivle(false)
        }
        if (type === 'rules') {
            setRulesVisivle(!rulesVisivle)
            setHistoryVisible(false)
        }
    }


    return (
        <FullScreen handle={screen} className="fullscreen">
            <div id="sweetforsweet">

                {/* Game control buttons */}
                <div className="game-controls">

                    <div className="game-control mobile-hide game-control-language">
                        <Picture src={`${env.mediapoint}/images/world.png`} alt="World" />
                        <div className="game-control-value">{language}</div>

                        <div className="game-control-language-container">
                            {languages.map((item, index) =>
                                <div onClick={() => setLanguage(item.code)} className={`game-control-language-item ${item.code === language ? 'active' : 'default'}`} key={`${index}`}>
                                    <div className="game-control-language-name">{item.name}</div>
                                    <div className="game-control-language-text">{item.code}</div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={`game-control mobile-hide ${historyVisible ? 'active' : 'default'}`} onClick={() => toggleButton('history')}>
                        <Picture src={`${env.mediapoint}/images/history.png`} alt="History" />
                    </div>

                    <div className={`game-control mobile-hide ${rulesVisivle ? 'active' : 'default'}`} onClick={() => toggleButton('rules')}>
                        <Picture src={`${env.mediapoint}/images/info.png`} alt="Info" />
                    </div>

                    <div className="game-control" onClick={() => setVolume(!volume)}>
                        {volume ? <Picture src={`${env.mediapoint}/images/volume.png`} alt="Volume" /> : <Picture src={`${env.mediapoint}/images/volume-mute.png`} alt="Volume" />}
                    </div>

                    <div className="game-control" onClick={() => mute()}>
                        {muted ? <Picture src={`${env.mediapoint}/images/music-off.png`} alt="Volume" /> : <Picture src={`${env.mediapoint}/images/music-on.png`} alt="Volume" />}
                    </div>

                    {!isSafari &&
                        <div className="game-control" onClick={() => handle()}>
                            <Picture src={`${env.mediapoint}/images/fullscreen.png`} alt="Fullscreen" />
                        </div>
                    }
                </div>

                {gameInfo && gameInfo.number && <div className="game-control-number">#{gameInfo.number}</div>}

                <div ref={modalRef}>

                    {/* Rules */}
                    <ModalAnimated visible={rulesVisivle}>
                        <Rules list={rules} close={() => setRulesVisivle(false)} />
                    </ModalAnimated>

                    <ModalAnimated visible={historyVisible}>
                        <History historyData={historyData} loadMore={() => loadMore()} close={() => setHistoryVisible(false)} />
                    </ModalAnimated>

                </div>


                {children}

                <div className="menu-toolbar" onClick={() => setMenuVisible(true)}>
                    <Picture src={`${env.mediapoint}/images/menu/burger.png`} alt="burger" />
                </div>

                <GameModal
                    visible={menuVisible}
                    close={() => setMenuVisible(false)}
                    selected={selected}
                    setSelected={selected => setSelected(selected)}
                >

                    <div className="menu-content">
                        {selected === '' &&
                            <div className="menu-select">

                                <div className="menu-select-button" onClick={() => setSelected('history')}>
                                    <Picture src={`${env.mediapoint}/images/menu/history.png`} alt='history' />
                                    <p>{utils.translate('History')}</p>
                                </div>
                                <div className="menu-select-button" onClick={() => setSelected('rules')}>
                                    <Picture src={`${env.mediapoint}/images/menu/rules.png`} alt='rules' />
                                    <p>{utils.translate('Rules')}</p>
                                </div>
                                <div className="menu-select-button" onClick={() => setSelected('payout')}>
                                    <Picture src={`${env.mediapoint}/images/menu/payout.png`} alt='payout' />
                                    <p>{utils.translate('Payout')}</p>
                                </div>

                                <Cash mobile />

                                <div className="game-controls menu">

                                    <div className="game-control game-control-language">
                                        <Picture src={`${env.mediapoint}/images/world.png`} alt="World" />
                                        <div className="game-control-value">{language}</div>

                                        <div className="game-control-language-container">
                                            {languages.map((item, index) =>
                                                <div onClick={() => setLanguage(item.code)} className={`game-control-language-item ${item.code === language ? 'active' : 'default'}`} key={`${index}`}>
                                                    <div className="game-control-language-name">{item.name}</div>
                                                    <div className="game-control-language-text">{item.code}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="game-control" onClick={() => mute()}>
                                        {muted ? <Picture src={`${env.mediapoint}/images/music-off.png`} alt="Volume" /> : <Picture src={`${env.mediapoint}/images/music-on.png`} alt="Volume" />}
                                    </div>

                                    <div className="game-control" onClick={() => setVolume(!volume)}>
                                        {volume ? <Picture src={`${env.mediapoint}/images/volume.png`} alt="Volume" /> : <Picture src={`${env.mediapoint}/images/volume-mute.png`} alt="Volume" />}
                                    </div>

                                    {!isSafari &&
                                        <div className="game-control" onClick={() => handle()}>
                                            <Picture src={`${env.mediapoint}/images/fullscreen.png`} alt="Fullscreen" />
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {selected === 'history' && <History historyData={historyData} mobile={true} loadMore={() => loadMore()} />}
                        {selected === 'payout' && <MobilePayout payout={payout} />}
                        {selected === 'rules' && <Rules mobile={true} list={rules} close={() => setRulesVisivle(false)} />}

                    </div>
                </GameModal>

            </div>
        </FullScreen>
    )

})

export default Background